// Layout
const VerticleLayout = () => import('../../../layouts/VerticleLayout')

const oldSystem = () => import('../views/index')

// start Routes
export default [
  {
    path: '/oldSystem',
    name: 'oldSystem',
    component: VerticleLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'oldSystem',
        meta: { auth: true, name: 'oldSystem' },
        component: oldSystem
      }
    ]
  }
]
