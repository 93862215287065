
import state from './moduleTaskCategoryState'
import mutations from './moduleTaskCategoryMutations'
import actions from './moduleTaskCategoryActions'
import getters from './moduleTaskCategoryGetters'

export default {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
