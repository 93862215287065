
import state from './moduleTargetState'
import mutations from './moduleTargetMutations'
import actions from './moduleTargetActions'
import getters from './moduleTargetGetters'

export default {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
