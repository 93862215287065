
import state from './moduleSystemSettingState.js'
import mutations from './moduleSystemSettingMutations.js'
import actions from './moduleSystemSettingActions.js'
import getters from './moduleSystemSettingGetters.js'

export default {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
