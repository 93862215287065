// Layout
const VerticleLayout = () => import('../../../layouts/VerticleLayout')

const wrongEntry = () => import('../views/list')

// start Routes
export default [
  {
    path: '/wrong-entry',
    name: 'wrongEntry',
    component: VerticleLayout,
    meta: { auth: true },
    children: [{
      path: '',
      name: 'wrongEntry',
      meta: { auth: true, name: 'wrongEntry' },
      component: wrongEntry
    }]
  }
]
