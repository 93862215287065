
import state from './moduleWhatsappState'
import mutations from './moduleWhatsappMutations'
import actions from './moduleWhatsappActions'
import getters from './moduleWhatsappGetters'

export default {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
