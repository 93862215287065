import Api from '@/axios'

export default {
  getAllUpgrades (pageNumber, memberId) {
    return Api().get(`memberships/upgrades?member_id=${memberId}&page=${pageNumber}`)
  },
  getAllInvitations (pageNumber, memberId, userName, dateFrom, dateTo) {
    return Api().get(`memberships/invitations?member_id=${memberId}&page=${pageNumber}&name=${userName}&date_from=${dateFrom}&date_to=${dateTo}`)
  },
  getAllPayments (pageNumber, memberId, userName, dateFrom, dateTo) {
    return Api().get(`memberships/payments?member_id=${memberId}&page=${pageNumber}&name=${userName}&date_from=${dateFrom}&date_to=${dateTo}`)
  },
  getAllVisits (pageNumber, memberId, userName, dateFrom, dateTo) {
    return Api().get(`memberships/visits?member_id=${memberId}&page=${pageNumber}&name=${userName}&date_from=${dateFrom}&date_to=${dateTo}`)
  },
  getAllFreeze (pageNumber, memberId, userName, dateFrom, dateTo) {
    return Api().get(`memberships/freezing?member_id=${memberId}&page=${pageNumber}&name=${userName}&date_from=${dateFrom}&date_to=${dateTo}`)
  },
  getAllDiscounts (pageNumber, memberId, status, objectId, userName, dateFrom, dateTo) {
    return Api().get(`memberships/discounts?member_id=${memberId}&page=${pageNumber}&status=${status}&object_id=${objectId}&name=${userName}&dateFrom=${dateFrom}&dateTo=${dateTo}`)
  },
  approveDiscount (id) {
    return Api().patch(`memberships/discounts/${id}`)
  },
  getLeadReport (filter, pageNumber, search, project, userId, callStatusId, inquiry, source, from, dateFrom, dateTo, ifNew, category, noHistory, userid, takenId, type) {
    if (filter) {
      if (noHistory) {
        return Api().get(`leads/reports/details?page=${pageNumber}&search=${search}&project_id=${project}&user=${userId}&inquiry_id=${inquiry}&source_id=${source}&from_id=${from}&date_from=${dateFrom}&date_to=${dateTo}&new=${ifNew}&category=${category}&no_history=1&user_id=${userid}&taken_id=${takenId}&filter=${filter}&type=${type}&${callStatusId.map((n, index) => `call_status_id[${index}]=${n}`).join('&')}`)
      } else { return Api().get(`leads/reports/details?page=${pageNumber}&search=${search}&project_id=${project}&user=${userId}&inquiry_id=${inquiry}&source_id=${source}&from_id=${from}&date_from=${dateFrom}&date_to=${dateTo}&new=${ifNew}&category=${category}&user_id=${userid}&taken_id=${takenId}&filter=${filter}&type=${type}&${callStatusId.map((n, index) => `call_status_id[${index}]=${n}`).join('&')}`) }
    } else {
      if (noHistory) {
        return Api().get(`leads/reports/details?page=${pageNumber}&search=${search}&project_id=${project}&user=${userId}&inquiry_id=${inquiry}&source_id=${source}&from_id=${from}&date_from=${dateFrom}&date_to=${dateTo}&new=${ifNew}&category=${category}&no_history=1&user_id=${userid}&taken_id=${takenId}&type=${type}&${callStatusId.map((n, index) => `call_status_id[${index}]=${n}`).join('&')}`)
      } else { return Api().get(`leads/reports/details?page=${pageNumber}&search=${search}&project_id=${project}&user=${userId}&inquiry_id=${inquiry}&source_id=${source}&from_id=${from}&date_from=${dateFrom}&date_to=${dateTo}&new=${ifNew}&category=${category}&user_id=${userid}&taken_id=${takenId}&type=${type}&${callStatusId.map((n, index) => `call_status_id[${index}]=${n}`).join('&')}`) }
    }
  },
  getSalesReport (pageNumber, projectId, dateFrom, dateTo, userId, user) {
    return Api().get(`sales/reports/details?page=${pageNumber}&project_id=${projectId}&date_from=${dateFrom}&date_to=${dateTo}&user_id=${userId}&user=${user}`)
  },
  getHistriesSalesReport (pageNumber, projectId, dateFrom, dateTo, userId, statusId) {
    return Api().get(`sales/reports/histories?page=${pageNumber}&project_id=${projectId}&date_from=${dateFrom}&date_to=${dateTo}&user_id=${userId}&${statusId.map((n, index) => `status_id[${index}]=${n}`).join('&')}`)
  },
  getAchievedSalesReport (pageNumber, projectId, dateFrom, dateTo, userId) {
    return Api().get(`sales/reports/achieved?page=${pageNumber}&project_id=${projectId}&date_from=${dateFrom}&date_to=${dateTo}&sales_id=${userId}`)
  },
  getPayableSalesReport (pageNumber, projectId, dateFrom, dateTo, userId) {
    return Api().get(`sales/reports/payable?page=${pageNumber}&project_id=${projectId}&date_from=${dateFrom}&date_to=${dateTo}&sales_id=${userId}`)
  },
  getLeadsSalesReport (pageNumber, projectId, dateFrom, dateTo, userId, status) {
    console.log('ssssssss')
    return Api().get(`sales/reports/leads?page=${pageNumber}&project_id=${projectId}&date_from=${dateFrom}&date_to=${dateTo}&sales_id=${userId}&${status.map((n, index) => `status_id[${index}]=${n}`).join('&')}`)
  },
  getContractsSalesReport (pageNumber, projectId, dateFrom, dateTo, userId) {
    return Api().get(`sales/reports/contracts?page=${pageNumber}&project_id=${projectId}&date_from=${dateFrom}&date_to=${dateTo}&sales_id=${userId}`)
  },
  getLeadSalesReport (pageNumber, projectId, dateFrom, dateTo, userId, callStatus, inquiryId, sourceId, category) {
    return Api().get(`sales/reports/leads?page=${pageNumber}&project_id=${projectId}&date_from=${dateFrom}&date_to=${dateTo}&sales_id=${userId}&call_status_id=${callStatus},inquiry_id=${inquiryId},source_id=${sourceId},category=${category}`)
  },
  getPackageReports (pageNumber, title, status, projectId) {
    return Api().get(`memberships/packages/details?page=${pageNumber}&title=${title}status=${status}&project_id=${projectId}`)
  }
}
