// Layout
const VerticleLayout = () => import('../../../layouts/VerticleLayout')

const roc = () => import('../views/list')

// start Routes
export default [
  {
    path: '/roc',
    name: 'roc',
    component: VerticleLayout,
    meta: { auth: true },
    children: [{
      path: '',
      name: 'roc',
      meta: { auth: true, name: 'roc' },
      component: roc
    }]
  }
]
