// Layout
const VerticleLayout = () => import('../../../layouts/VerticleLayout')

const AmCharts = () => import('../views/AmCharts')
const ApexCharts = () => import('../views/ApexCharts')
const HighCharts = () => import('../views/HighCharts')

// start Routes
export default [
  {
    path: '/charts',
    name: 'charts',
    component: VerticleLayout,
    meta: { auth: true },
    children: [{
      path: '',
      name: 'charts',
      meta: { auth: true, name: 'charts' },
      component: AmCharts
    }]
  }, {
    path: '/apex',
    name: 'charts',
    component: VerticleLayout,
    meta: { auth: true },
    children: [{
      path: '',
      name: 'apex',
      meta: { auth: true, name: 'apex' },
      component: ApexCharts
    }]
  }, {
    path: '/high',
    name: 'high',
    component: VerticleLayout,
    meta: { auth: true },
    children: [{
      path: '',
      name: 'high',
      meta: { auth: true, name: 'high' },
      component: HighCharts
    }]
  }
]
