// Layout
const VerticleLayout = () => import('../../../layouts/VerticleLayout')

const mainCampaign = () => import('../views/index')
const whatsappCampaign = () => import('../views/whatsapp')
const smsCampaign = () => import('../views/sms')

// start Routes
export default [
  {
    path: '/campaign',
    name: 'campaign',
    component: VerticleLayout,
    meta: { auth: true },
    children: [{
      path: '',
      name: 'campaign',
      meta: { auth: true, name: 'campaign' },
      component: mainCampaign,
      children: [{
        path: '',
        name: 'campaign',
        meta: { auth: true, name: 'campaign' },
        component: whatsappCampaign
      },
      {
        path: 'sms',
        name: 'sms',
        meta: { auth: true, name: 'sms' },
        component: smsCampaign
      }]
    }]
  }
]
