
import state from './moduleComplainState'
import mutations from './moduleComplainMutations'
import actions from './moduleComplainActions'
import getters from './moduleComplainGetters'

export default {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
