// Layout
const VerticleLayout = () => import('@/layouts/VerticleLayout')

const index = () => import('../views/index')
const chartOfAccounts = () => import('../views/chartOfAccounts')
// const fixedAssets = () => import('../views/fixedAssets')
const paymentMethods = () => import('../views/paymentMethods')
const classefications = () => import('../views/classifications')
const createEntry = () => import('../views/entries/createEntries.vue')
const listEntry = () => import('../views/entries/list.vue')

// start Routes
export default [
  {
    path: '/finance/settings',
    name: 'chartOfAccounts',
    component: VerticleLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'settingIndex',
        meta: { auth: true, name: 'settingIndex' },
        component: index,
        children: [
          {
            path: 'chart-of-accounts',
            name: 'chartOfAccounts',
            meta: { auth: true, name: 'chartOfAccounts' },
            component: chartOfAccounts
          },
          // {
          //   path: 'fixed-assets',
          //   name: 'fixedAssets',
          //   meta: { auth: true, name: 'fixedAssets' },
          //   component: fixedAssets
          // },
          {
            path: 'payment_methods',
            name: 'payment_methods',
            meta: { auth: true, name: 'payment_methods' },
            component: paymentMethods
          },
          {
            path: 'classefications',
            name: 'classefications',
            meta: { auth: true, name: 'classefications' },
            component: classefications
          },
          {
            path: 'createEntry',
            name: 'createEntry',
            meta: { auth: true, name: 'createEntry' },
            component: createEntry
          },
          {
            path: 'entries',
            name: 'entries',
            meta: { auth: true, name: 'entries' },
            component: listEntry
          }
        ]
      }
    ]
  }
]
