// Layout
const VerticleLayout = () => import('../../../layouts/VerticleLayout')

const mainList = () => import('../views/list')
const incomeReport = () => import('../views/income')
const transactions = () => import('../views/transactions')

// start Routes
export default [
  {
    path: '/financialReport',
    name: 'incomeReport',
    component: VerticleLayout,
    meta: { auth: true },
    children: [{
      path: '',
      name: 'incomeReport',
      meta: { auth: true, name: 'incomeReport' },
      component: mainList,
      children: [
        {
          path: '',
          name: 'incomeReport',
          meta: { auth: true, name: 'incomeReport' },
          component: incomeReport
        },
        {
          path: 'transactions',
          name: 'transactionReports',
          meta: { auth: true, name: 'transactionReports' },
          component: transactions
        }
      ]
    }]
  }
]
