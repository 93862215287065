
import state from './moduleTreeState'
import mutations from './moduleTreeMutations'
import actions from './moduleTreeActions'
import getters from './moduleTreeGetters'

export default {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
