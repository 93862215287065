// Layout
const VerticleLayout = () => import('../../../layouts/VerticleLayout')

const fileList = () => import('../views/list')
const folderDetails = () => import('../views/allFiles')

// start Routes
export default [
  {
    path: '/file-manager',
    name: 'fileManager',
    component: VerticleLayout,
    meta: { auth: true },
    children: [{
      path: '',
      name: 'fileManager',
      meta: { auth: true, name: 'fileManager' },
      component: fileList
    }, {
      path: 'folder/:id',
      name: 'folderDetails',
      meta: { auth: true, name: 'folderDetails' },
      component: folderDetails
    }]
  }
]
